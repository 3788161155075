import React from "react";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import * as homeStyles from "../styles/home.module.css";

export default function IndexPage({ data }) {
  return (
    <Layout pageTitle="Home Page">
      <div className={`${homeStyles.homeHeader} pageHeaderBar`}>
        <div className={homeStyles.homeHeaderImages}>
          <StaticImage
            alt="Dolce Vita, the Maine Coon, peering into the camera across her favourite little nest."
            src="../images/dolce-vita.jpg"
            height="200"
            style={{}}
          />
          <StaticImage
            alt="Quantico, aka Squeeky, aka Little Man, doing his thing."
            src="../images/quantico.jpg"
            height="200"
            style={{}}
          />
        </div>
      </div>
      <div className={homeStyles.homeMainContainer}>
        <h1>Tutorials</h1>
        <p>
          While you are here, make sure to check out our latest{" "}
          <Link to="/blog">tutorials</Link>
        </p>
      </div>
    </Layout>
  );
}
